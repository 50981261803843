import React from "react";
import { PortableText } from "@portabletext/react";
import Heading from "../Heading";

const portableTextComponents = {
  block: {
    h1: ({ children }) => (
      <Heading level={1} className="mb-16">
        {children}
      </Heading>
    ),
    h2: ({ children }) => (
      <Heading level={2} className="mb-16">
        {children}
      </Heading>
    ),
    h3: ({ children }) => (
      <Heading level={3} className="mb-8">
        {children}
      </Heading>
    ),
    h4: ({ children }) => (
      <Heading level={4} className="mt-4 mb-2">
        {children}
      </Heading>
    ),
    normal: ({ children }) => <p className="mb-4">{children}</p>,
  },
  list: {
    bullet: ({ children }) => (
      <ul className="list-disc ml-8 mb-4">{children}</ul>
    ),
  },
  listItem: {
    bullet: ({ children }) => <li>{children}</li>,
  },
};

const SanityPortableText = ({ value }) => (
  <PortableText value={value} components={portableTextComponents} />
);

export default SanityPortableText;
