export const gaEvent = (category, action, label) => {
  if (process.env.NODE_ENV === "production") {
    try {
      window.gtag("event", action, {
        event_category: category,
        event_label: label,
      });
    } catch (error) {
      console.error(error);
    }
  }
};

export const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};
