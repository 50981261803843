import React, { useState, Fragment, useRef, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useForm, Controller } from "react-hook-form";
import { Listbox, Transition } from "@headlessui/react";
import Button from "../Button";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { gaEvent, encode } from "../../helpers";

import "react-phone-number-input/style.css";

const form_name = "newsletter";

export default function NewsletterForm({
  afterSubmit,
  selectedLocationSlug,
  headingClassNames,
  title,
}) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const firstNameInputRef = useRef(null);
  const { ref: firstNameRef, ...firstNameRest } = register("firstName", {
    required: true,
  });
  const locationData = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "location-page" } } }
        sort: { order: ASC, fields: [frontmatter___order] }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              location
              sub_category
              slug
            }
          }
        }
      }
    }
  `);
  const locations = locationData.allMarkdownRemark.edges;
  var preSelectedLocation = null;
  if (selectedLocationSlug) {
    var tempLocation = locations.filter((loc) => {
      return loc.node.frontmatter.slug === selectedLocationSlug;
    });
    if (tempLocation && tempLocation.length > 0) {
      preSelectedLocation = tempLocation[0].node;
    }
    setValue("locationsChoice", tempLocation[0].node.frontmatter.location);
  }

  const [locationSelected, setLocationSelected] = useState(preSelectedLocation);

  useEffect(() => {
    firstNameInputRef.current.focus();
  }, []);

  const onSubmit = (data, e) => {
    gaEvent("Form", "Submit", "Newsletter Submit");
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": form_name, ...data }),
    })
      .then(() => {
        afterSubmit();
      })
      .catch((error) => alert(error));

    //Transition to Tick, then
  };
  const onError = (errors, e) => console.error(errors, e);

  const headingClassName =
    headingClassNames ||
    "text-2xl text-heading uppercase text-center leading-6 font-medium text-gray-900";

  const constantInputClassName = "shadow-sm block w-full sm:text-md ";

  const inputClassName =
    constantInputClassName +
    "focus:ring-feat-blue-500 focus:border-feat-blue-500 border-gray-300 placeholder-feat-darkgrey-300";

  const errorInputClassName =
    constantInputClassName +
    "focus:ring-red-500 focus:border-red-500 border-red-300 placeholder-red-300";

  return (
    <form
      className="space-y-8"
      onSubmit={handleSubmit(onSubmit, onError)}
      name={form_name}
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value={form_name} />
      <input {...register("bot-field")} type="hidden" />
      <div className="">
        <div>
          <h2 className={headingClassName}>
            {title ? title : `Join our Newsletter`}
          </h2>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <input
              type="text"
              {...firstNameRest}
              autoComplete="given-name"
              placeholder="First Name *"
              className={
                errors["firstName"] ? errorInputClassName : inputClassName
              }
              ref={(e) => {
                firstNameRef(e);
                firstNameInputRef.current = e;
              }}
            />
          </div>

          <div className="sm:col-span-3">
            <input
              type="text"
              {...register("lastName", { required: true })}
              autoComplete="family-name"
              placeholder="Last Name *"
              className={
                errors["lastName"] ? errorInputClassName : inputClassName
              }
            />
          </div>

          <div className="sm:col-span-6">
            <input
              {...register("email", { required: true })}
              type="email"
              autoComplete="email"
              placeholder="Email *"
              className={classNames(
                "focus:ring-feat-blue-500 focus:border-feat-blue-500",
                errors["email"] ? errorInputClassName : inputClassName
              )}
            />
          </div>
          <div className="sm:col-span-6">
            <PhoneInput
              name="mobile"
              control={control}
              rules={{ required: false }}
              className={
                errors["mobile"] ? errorInputClassName : inputClassName
              }
              defaultCountry="AU"
              type="tel"
              autoComplete="tel"
              placeholder="Mobile"
            />
          </div>
          <div className="sm:col-span-6">
            <input
              type="hidden"
              name="locationsChoice"
              value={locationSelected || ""}
            />
            <Controller
              name="locationsChoice"
              control={control}
              defaultValue={null}
              rules={{ required: false }}
              render={({ field: { onChange } }) => (
                <Listbox
                  value={locationSelected || ""}
                  onChange={(e) => {
                    if (e === "Other") {
                      onChange(e);
                    } else {
                      onChange(e.frontmatter.location);
                    }
                    setLocationSelected(e);
                  }}
                >
                  <div className="relative">
                    <Listbox.Button
                      className={classNames(
                        "relative w-full bg-white border shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1  sm:text-md",
                        errors["locationsChoice"]
                          ? "border-red-300 focus:ring-red focus:border-red"
                          : "border-gray-300 focus:ring-feat-blue focus:border-feat-blue"
                      )}
                    >
                      <span className="w-full inline-flex truncate">
                        {locationSelected ? (
                          <>
                            {locationSelected === "Other" ? (
                              <>
                                <span className="truncate">
                                  Other - let me specify
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="truncate">
                                  {locationSelected?.frontmatter.location}
                                </span>
                                <span className="ml-2 truncate text-feat-darkgrey">
                                  {locationSelected?.frontmatter.sub_category}
                                </span>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <span
                              className={classNames(
                                "truncate",
                                errors["locationsChoice"]
                                  ? "text-red-300"
                                  : " text-feat-darkgrey-300"
                              )}
                            >
                              Select a Training Location
                            </span>
                          </>
                        )}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className={classNames(
                            "h-5 w-5",
                            errors["locationsChoice"]
                              ? "text-red-400"
                              : " text-feat-darkgrey-400"
                          )}
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        {locations.map(({ node: location }) => (
                          <Listbox.Option
                            key={location.frontmatter.location}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-feat-blue"
                                  : "text-gray-900",
                                "cursor-default select-none relative py-2 pl-3 pr-9"
                              )
                            }
                            value={location}
                          >
                            {({ locationSelected, active }) => (
                              <>
                                <div className="flex">
                                  <span
                                    className={classNames(
                                      locationSelected
                                        ? "font-semibold"
                                        : "font-normal",
                                      "truncate"
                                    )}
                                  >
                                    {location.frontmatter.location}
                                  </span>
                                  <span
                                    className={classNames(
                                      active
                                        ? "text-feat-blue-50"
                                        : "text-feat-darkgrey",
                                      "ml-2 truncate"
                                    )}
                                  >
                                    {location.frontmatter.sub_category}
                                  </span>
                                </div>

                                {locationSelected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-feat-blue",
                                      "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                        <Listbox.Option
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-feat-blue"
                                : "text-gray-900",
                              "cursor-default select-none relative py-2 pl-3 pr-9"
                            )
                          }
                          value="Other"
                        >
                          <div className="flex">
                            <span
                              className={classNames(
                                locationSelected === "Other"
                                  ? "font-semibold"
                                  : "font-normal",
                                "truncate"
                              )}
                            >
                              Other
                            </span>
                            <span
                              className={classNames(
                                locationSelected === "Other"
                                  ? "text-feat-blue-50"
                                  : "text-feat-darkgrey",
                                "ml-2 truncate italic"
                              )}
                            >
                              Let me specify
                            </span>
                          </div>
                        </Listbox.Option>
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              )}
            />
          </div>
          {locationSelected === "Other" ? (
            <div className="sm:col-span-6">
              <input
                type="text"
                {...register("locationOtherField", {})}
                placeholder="Tell us where"
                className={classNames(
                  errors["locationOtherField"]
                    ? errorInputClassName
                    : inputClassName
                )}
              />
            </div>
          ) : (
            <input type="hidden" name="locationOtherField" />
          )}
        </div>
      </div>

      <div className="pt-3">
        <div className="">
          <Button
            type="submit"
            className="w-full uppercase font-heading text-center text-white bg-feat-blue hover:bg-feat-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-feat-blue"
          >
            Keep me updated
          </Button>
          <div className="pt-2 text-xs text-feat-lightgray-700 text-right">
            By submitting this form, you agree to our FEAT Fitness{" "}
            <a
              href="/privacy-policy"
              target="_blank"
              className="text-feat-blue-300 cursor-pointer"
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </div>
    </form>
  );
}
