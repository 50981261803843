import React, { useState, useRef } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import classNames from "classnames";
import { Fragment } from "react";
import { Disclosure, Popover, Transition } from "@headlessui/react";
import { MenuIcon, PlayIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { useModal } from "../../../provider";
import { gaEvent } from "../../helpers";

const callsToAction = [
    { name: "See All Our Locations", href: "/location", icon: PlayIcon },
];

const disclosureButtonClassNames =
    "font-heading uppercase font-thin text-base text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-8 focus:ring-feat-lightgrey hover:bg-gray-50 hover:border-gray-300 block pl-3 pr-4 py-2 border-l-4";
const disclosureButtonActiveClassNames = "text-feat-blue";

const disclosureButtonSubLinkClassNames =
    "font-heading uppercase font-thin text-base text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-8 focus:ring-feat-lightgrey hover:bg-gray-50 hover:border-gray-300 block pl-3 pr-4 py-1";

const NavBarExample = () => {
    const location_data = useStaticQuery(graphql`
        query {
            allMarkdownRemark(
                filter: {
                    frontmatter: { templateKey: { eq: "location-page" } }
                }
                sort: { order: ASC, fields: [frontmatter___order] }
            ) {
                edges {
                    node {
                        id
                        fields {
                            slug
                        }
                        frontmatter {
                            location
                            sub_category
                            is_primary
                        }
                    }
                }
            }
        }
    `);
    const locations = location_data.allMarkdownRemark.edges;
    const buttonRef = useRef(null); // useRef<HTMLButtonElement>(null)

    const [openState, setOpenState] = useState(false);

    const toggleMenu = () => {
        setOpenState((openState) => !openState);
        buttonRef?.current?.click(); // eslint-disable-line
    };

    const onHover = (open, action) => {
        if (
            (!open && !openState && action === "onMouseEnter") ||
            (open && openState && action === "onMouseLeave")
        ) {
            toggleMenu(open);
        }
    };

    const modal = useModal();

    return (
        <Disclosure as="nav" className="sticky top-0 bg-white z-50 w-full">
            {({ open }) => (
                <>
                    <Popover className=" border-b-2 border-gray-100">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="flex justify-between">
                                <div className="flex py-6">
                                    <Link
                                        to="/"
                                        className="flex-shrink-0 flex items-center flex-none sm:mr-8"
                                        partiallyActive={true}
                                    >
                                        <span className="sr-only">
                                            FEAT Fitness
                                        </span>
                                        <img
                                            className="block md:hidden xl:block h-8 w-auto sm:h-10"
                                            src="/img/FEAT_Fitness_Logo.svg"
                                            alt="FEAT Fitness Logo"
                                        />
                                        <img
                                            className="hidden md:block xl:hidden h-8 w-auto sm:h-10"
                                            src="/img/FEAT_Fitness_Logo_Swish.svg"
                                            alt="FEAT Fitness Logo"
                                        />
                                    </Link>
                                    <div className="hidden items-center md:flex md:space-x-8">
                                        <Link
                                            to="/our-training"
                                            className="font-heading uppercase font-thin text-center text-base text-gray-500 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-8 focus:ring-feat-lightgrey"
                                            activeClassName="text-feat-blue"
                                            partiallyActive={true}
                                        >
                                            Our Training
                                        </Link>
                                        <Link
                                            to="/location/maroubra-sydney"
                                            className="font-heading uppercase font-thin text-center text-base text-gray-500 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-8 focus:ring-feat-lightgrey"
                                            activeClassName="text-feat-blue"
                                            partiallyActive={true}
                                        >
                                            Maroubra Beach
                                        </Link>
                                        <Link
                                            to="/getting-started"
                                            className="font-heading uppercase font-thin text-center text-base text-gray-500 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-8 focus:ring-feat-lightgrey"
                                            activeClassName="text-feat-blue"
                                            partiallyActive={true}
                                        >
                                            Getting Started
                                        </Link>
                                        <Link
                                            to="/about-us"
                                            className="font-heading uppercase font-thin text-center text-base text-gray-500 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-8 focus:ring-feat-lightgrey"
                                            activeClassName="text-feat-blue"
                                            partiallyActive={true}
                                        >
                                            About Us
                                        </Link>
                                        <Link
                                            to="/feat-members"
                                            className="font-heading uppercase font-thin text-center text-base text-gray-500 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-8 focus:ring-feat-lightgrey"
                                            activeClassName="text-feat-blue"
                                            partiallyActive={true}
                                        >
                                            Members
                                        </Link>
                                    </div>
                                </div>
                                <div className="hidden md:flex items-center justify-end md:flex-1 xl:w-0">
                                    <a
                                        href="#"
                                        onClick={() => {
                                            gaEvent(
                                                "Button",
                                                "FreeTrial",
                                                "free trial - navbar to modal"
                                            );
                                            modal.openFreeTrialModal();
                                        }}
                                        className="ml-8 uppercase whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-feat-orange hover:bg-feat-orange-200"
                                    >
                                        Free Week Trial
                                    </a>
                                </div>
                                <div className="-mr-2 flex items-center -my-2 md:hidden">
                                    <Disclosure.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-feat-blue-300">
                                        <span className="sr-only">
                                            Open menu
                                        </span>
                                        {open ? (
                                            <XIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <MenuIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>
                    </Popover>
                    <Disclosure.Panel className="md:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            <a
                                href="#"
                                onClick={() => {
                                    gaEvent(
                                        "Button",
                                        "FreeTrial",
                                        "free trial - navbar to modal mobile"
                                    );
                                    modal.openFreeTrialModal();
                                }}
                                className="w-full mb-2 uppercase whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-feat-orange hover:bg-feat-orange-200"
                            >
                                Free Week Trial
                            </a>
                            <Link
                                to="/our-training"
                                className={disclosureButtonClassNames}
                                activeClassName={
                                    disclosureButtonActiveClassNames
                                }
                                partiallyActive={true}
                            >
                                Our Training
                            </Link>
                            <Link
                                to="/location"
                                className={disclosureButtonClassNames}
                                activeClassName={
                                    disclosureButtonActiveClassNames
                                }
                                partiallyActive={true}
                            >
                                Locations & Training
                            </Link>
                            {locations
                                .filter(
                                    ({ node: item }) =>
                                        item.frontmatter?.is_primary === true
                                )
                                .map(({ node: item }) => (
                                    <Link
                                        key={item.frontmatter?.location}
                                        to={item.fields?.slug}
                                        className={
                                            disclosureButtonSubLinkClassNames
                                        }
                                        activeClassName={
                                            disclosureButtonActiveClassNames
                                        }
                                        partiallyActive={true}
                                    >
                                        <div className="ml-4 flex items-center">
                                            <p className="text-base">
                                                {item.frontmatter?.location}
                                            </p>
                                            <p className="pl-2 text-xs font-medium text-gray-400">
                                                {item.frontmatter?.sub_category}
                                            </p>
                                        </div>
                                    </Link>
                                ))}
                            <Link
                                to="/getting-started"
                                className={disclosureButtonClassNames}
                                activeClassName={
                                    disclosureButtonActiveClassNames
                                }
                                partiallyActive={true}
                            >
                                Getting Started
                            </Link>
                            <Link
                                to="/about-us"
                                className={disclosureButtonClassNames}
                                activeClassName={
                                    disclosureButtonActiveClassNames
                                }
                                partiallyActive={true}
                            >
                                About Us
                            </Link>
                            <Link
                                to="/feat-members"
                                className={disclosureButtonClassNames}
                                activeClassName={
                                    disclosureButtonActiveClassNames
                                }
                                partiallyActive={true}
                            >
                                Members
                            </Link>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};

export default NavBarExample;
