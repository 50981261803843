import React from "react";
import classNames from "classnames";

export default function Heading({
  className,
  children,
  level,
  color,
  size,
  ...props
}) {
  const HeadingTag = typeof level === "number" ? "h" + level : level;
  var headingClasses;
  if (HeadingTag === "h1") {
    headingClasses = `text-heading uppercase text-${size || "5xl"} text-${
      color || "feat-blue"
    }`;
  } else if (HeadingTag === "h2") {
    headingClasses = `text-heading uppercase text-${size || "4xl"} text-${
      color || "feat-darkgray"
    }`;
  } else if (HeadingTag === "h3") {
    headingClasses = `text-heading uppercase text-${size || "2xl"} text-${
      color || "feat-darkgray"
    }`;
  } else if (HeadingTag === "h4") {
    headingClasses = `text-base ${size && `text-${size}`} text-${
      color || "feat-darkgray-300"
    }`;
  } else {
    headingClasses = `text-heading uppercase ${size && `text-${size}`} ${
      color && `text-${color}`
    }`;
  }

  return (
    <HeadingTag className={classNames(headingClasses, className)} {...props}>
      {children}
    </HeadingTag>
  );
}
