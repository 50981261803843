import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { GatsbyImage } from "gatsby-plugin-image";
import { useModal } from "../../../../provider";
import SanityPortableText from "../../SanityPortableText/SanityPortableText";

export default function TrainerModal() {
    const modal = useModal();
    const trainer = modal.trainerModalData;
    if (!trainer) {
        return <></>;
    }
    return (
        <Transition.Root show={modal.trainerModalOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed z-50 inset-0 overflow-y-auto"
                onClose={() => {
                    modal.setTrainerModalOpen(false);
                }}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
                            <div className="relative">
                                <div className="w-full flex flex-col-reverse md:flex-row">
                                    <div className="flex-1 p-6 text-left">
                                        <h3 className="text-heading uppercase text-feat-blue text-3xl mb-4">
                                            {trainer.title}
                                        </h3>
                                        {trainer._rawBody && (
                                            <SanityPortableText
                                                value={trainer._rawBody}
                                            />
                                        )}
                                    </div>
                                    {trainer && (
                                        <GatsbyImage
                                            image={
                                                trainer.smilePhoto?.asset
                                                    .gatsbyImageData
                                            }
                                            alt={trainer.title}
                                            className="flex-1 inset-y-0 right-0 w-half object-cover z-0 max-h-40-screen md:max-h-full"
                                            imgClassName="object-30-from-top md:object-center"
                                        />
                                    )}
                                </div>
                                <div className="absolute right-4 top-4 transform transition-all sm:align-middle sm:w-full sm:p-6 ">
                                    <div className="block absolute top-0 right-0 pt-2 pr-2 z-40">
                                        <button
                                            type="button"
                                            className="rounded-md text-gray-400 hover:text-gray-500"
                                            onClick={() =>
                                                modal.setTrainerModalOpen(false)
                                            }
                                        >
                                            <span className="sr-only">
                                                Close
                                            </span>
                                            <XIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
