import React from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import { XIcon } from "@heroicons/react/outline";
import { useModal } from "../../../../provider";
import JobApplicationForm from "../../Forms/JobApplicationForm";
import { navigate } from "gatsby-link";

export default function JobApplicationModal() {
  const modal = useModal();
  return (
    <DialogOverlay
      isOpen={modal.jobApplicationModalOpen}
      as="div"
      className="fixed z-50 inset-0 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity"
      onDismiss={() => modal.setJobApplicationModalOpen(false)}
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <DialogContent aria-label="Apply for a job">
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div className="inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div className="block absolute top-0 right-0 pt-4 pr-4">
              <button
                type="button"
                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-feat-lightgrey"
                onClick={() => modal.setJobApplicationModalOpen(false)}
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <JobApplicationForm
              afterSubmit={() => {
                modal.setJobApplicationModalOpen(false);
                navigate("/jobs/submitted");
              }}
              selectedJobSlug={modal.selectedJobSlug}
            />
          </div>
        </DialogContent>
      </div>
    </DialogOverlay>
  );
}
