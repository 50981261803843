import React from "react";
import { Link } from "gatsby";
import { useModal } from "../../../provider";
import { gaEvent } from "../../helpers";

const navigation = {
    solutions: [
        { name: "FEAT Fitness", href: "/" },
        { name: "FEAT Members", href: "/feat-members" },
        { name: "Our Trainers", href: "/our-trainers" },
        { name: "Jobs", href: "/jobs" },
    ],
    support: [
        { name: "Free Trial", href: "/freetrial" },
        {
            name: "Pre Training Questionnaire",
            href: "/pre-training-questionnaire",
        },
        { name: "Blog", href: "/blog" },
    ],
    company: [
        { name: "COVID Safe", href: "/covid-19" },
        { name: "FAQs", href: "/faqs" },
        { name: "Privacy Policy", href: "/privacy-policy" },
        { name: "Child Safe Policy", href: "/child-safe-policy" },
    ],
    social: [
        {
            name: "Facebook",
            href: "https://facebook.com/FEATFitnessMaroubra",
            tag: "FEATFitnessMaroubra",
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        {
            name: "Instagram",
            href: "https://www.instagram.com/featfitness_maroubra/",
            tag: "@FEATFitnessMaroubra",
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
    ],
};

const Footer = () => {
    const modal = useModal();
    return (
        <footer className="bg-white" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 xl:py-16 xl:px-8">
                <div className="xl:grid xl:grid-cols-8 xl:gap-8">
                    <div className="space-y-4 xl:col-span-2 text-center">
                        <img
                            className="h-10 mx-auto"
                            src="/img/FEAT_Fitness_Logo.svg"
                            alt="FEAT Fitness Logo"
                        />

                        <p className="text-gray-500 text-base">
                            Fun Community Outdoor Training
                        </p>
                    </div>

                    <div className="mt-12 grid grid-cols-3 gap-4 sm:px-8 px-0 xl:mt-0 xl:col-span-4">
                        <div>
                            <ul className="space-y-4 text-center xl:text-left">
                                {navigation.solutions.map((item) => (
                                    <li key={item.name}>
                                        <Link
                                            to={item.href}
                                            className="text-base text-gray-500 hover:text-gray-900"
                                        >
                                            {item.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <ul className="space-y-4 text-center xl:text-left">
                                {navigation.support.map((item) => (
                                    <li key={item.name}>
                                        <Link
                                            to={item.href}
                                            className="text-base text-gray-500 hover:text-gray-900"
                                        >
                                            {item.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <ul className="space-y-4 text-center xl:text-left">
                                {navigation.company.map((item) => (
                                    <li key={item.name}>
                                        <Link
                                            to={item.href}
                                            className="text-base text-gray-500 hover:text-gray-900"
                                        >
                                            {item.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="space-y-4 xl:col-span-2 xl:pt-0 pt-12 xl:text-left text-center">
                        <div className="text-heading uppercase font-bold text-gray-500">
                            Contact Us
                        </div>
                        {navigation.social.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className="text-gray-500 hover:text-gray-900 flex xl:justify-start justify-center"
                                target="_blank"
                            >
                                <span className="sr-only">{item.name}</span>
                                <item.icon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                                <span className="pl-2">{item.tag}</span>
                            </a>
                        ))}
                    </div>
                </div>
                <div className="mt-12 border-t border-gray-200 pt-8">
                    <p className="text-base text-gray-400 xl:text-center">
                        &copy; 2022 FEAT Solutions Pty Ltd. All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
