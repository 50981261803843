module.exports = {
  siteTitle: "FEAT Fitness", // Site title.
  siteTitleAlt: "Fun Community Based Training", // Alternative site title for Seo.
  siteLogo: "/logos/logo-512x512.png", // Logo used for Seo and manifest.
  siteUrl: "https://featfitness.com.au", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: "Fun community based training in your local outdoor spaces.", // Website description used for RSS feeds/meta description tag.
  siteFBAppID: "716156415430031", // FB Application ID for using app insights
  googleTagManagerID: "GTM-W5RXH4W", // GTM tracking ID.
  copyright: "Copyright © FEAT Fitness 2022. All Rights Reserved.", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#add533", // Used for setting manifest and progress theme colors.
  backgroundColor: "#ffffff", // Used for setting manifest background color.
  mapboxAccessToken:
    "pk.eyJ1Ijoicm9iZmVhdGZpdG5lc3MiLCJhIjoiY2t1NThmbnBzNHl3azJwcHFyNGZ2YXVvOCJ9.ggAUu1yiNYgHXKLIDfPnQw",
  googleApiKey: "AIzaSyAL6mdzj35oXk43XD2d2XS-XKDYfgfZ5rU",
};
