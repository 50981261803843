import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import NavBar from "./NavBar";
import Footer from "./Footer";
import config from "../../meta/config";
import FreeTrialModal from "./Modal/FreeTrialModal";
import TrainerModal from "./Modal/TrainerModal";
import PreTrainingQuestionnaireModal from "./Modal/PreTrainingQuestionnaireModal";
import NewsletterModal from "./Modal/NewsletterModal";
import JobApplicationModal from "./Modal/JobApplicationModal";

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = { isActive: false };
        this.toggleNavbar = this.toggleNavbar.bind(this);
    }

    toggleNavbar() {
        this.setState({ isActive: !this.state.isActive });
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>{config.siteTitle}</title>
                    <meta name="description" content={config.siteDescription} />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,700&display=swap"
                        rel="stylesheet"
                    ></link>
                    <meta name="fb:app_id" content={config.siteFBAppID} />
                    {process.env.NODE_ENV === "production" && (
                        <script
                            src="https://cdn.logrocket.io/LogRocket.min.js"
                            crossOrigin="anonymous"
                        />
                    )}
                    {process.env.NODE_ENV === "production" && (
                        <script>
                            window.LogRocket &&
                            window.LogRocket.init('m4gm9z/feat-website');
                        </script>
                    )}
                </Helmet>
                <NavBar
                    isActive={this.state.isActive}
                    toggleNavbar={() => this.toggleNavbar()}
                />
                <div className="number-header-padding" />
                <div>{this.props.children}</div>
                <Footer />
                <FreeTrialModal />
                <PreTrainingQuestionnaireModal />
                <TrainerModal />
                <NewsletterModal />
                <JobApplicationModal />
            </>
        );
    }
}
Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
