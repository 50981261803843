import React, { useState, Fragment, useRef, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useForm, Controller } from "react-hook-form";
import { Listbox, Transition } from "@headlessui/react";
import Button from "../Button";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import DatePicker from "react-datepicker";
import { gaEvent, encode } from "../../helpers";
import { googleApiKey } from "../../../meta/config";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import _ from "lodash";
import { useModal } from "../../../provider";

import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";

const form_name = "pre-training-questionnaire";

const healthOptions = [
  "Arthritis",
  "Asthma",
  "Diabetes",
  "Epilepsy",
  "Osteoporosis",
  "Dizziness",
  "Chest pain",
  "Heart Problems/disease",
  "High cholesterol",
  "Stroke",
  "Family history of heart disease or stroke",
  "High or Low blood pressure",
  "Other?",
];

const steps = [
  { name: "Step 1" },
  { name: "Step 2" },
  { name: "Step 3" },
  { name: "Step 4" },
];

export default function PreTrainingQuestionnaireForm({
  afterSubmit,
  selectedLocationSlug,
  headingClassNames,
}) {
  const { preFillData } = useModal();
  const [formStep, setFormStep] = useState(1);
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { isValid, errors },
    trigger,
    setValue,
  } = useForm({ mode: "onChange" });
  const firstNameInputRef = useRef(null);
  const { ref: firstNameRef, ...firstNameRest } = register("firstName", {
    required: formStep >= 1,
  });
  const [currentlyTraining, setCurrentlyTraining] = useState(null);
  const watchCurrentlyTraining = watch("currentlyTraining");
  const watchHealthOther = watch("health_other");
  const watchVaccinated = watch("vaccinated");

  const healthInformationYesNoMore = [
    {
      label:
        "Do you have any current injuries or is there anything else that might affect your ability to participate?",
      name: "doYouHaveInjuries",
      askMore: true,
      watch: watch("doYouHaveInjuries"),
    },
    {
      label: "Do you have any Allergies?",
      name: "doYouHaveAllergies",
      askMore: true,
      watch: watch("doYouHaveAllergies"),
    },
    {
      label: "Are you currently taking any medication/s?",
      name: "doYouTakeMedication",
      askMore: true,
      watch: watch("doYouTakeMedication"),
    },
    {
      label: "Are you, or have you recently been pregnant?",
      name: "areYouPregnant",
      askMore: false,
    },
  ];

  const locationData = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "location-page" } } }
        sort: { order: ASC, fields: [frontmatter___order] }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              location
              sub_category
              slug
            }
          }
        }
      }
    }
  `);
  const locations = locationData.allMarkdownRemark.edges;
  var preSelectedLocation = null;
  if (selectedLocationSlug || preFillData.locationsChoice) {
    var tempLocation = locations.filter((loc) => {
      if (selectedLocationSlug) {
        return loc.node.frontmatter.slug === selectedLocationSlug;
      }

      return loc.node.frontmatter.location === preFillData.locationsChoice;
    });
    if (tempLocation && tempLocation.length > 0) {
      preSelectedLocation = tempLocation[0].node;
    }
    setValue("locationsChoice", tempLocation[0].node.frontmatter.location);
  }
  const [locationSelected, setLocationSelected] = useState(preSelectedLocation);
  const [hearAboutUsSelected, setHearAboutUsSelected] = useState(null);
  const [address, setAddress] = useState(null);

  const hearAboutUsOptions = [
    { id: "Word of Mouth", title: "Word of Mouth" },
    { id: "Friend Invited Me", title: "Friend Invited Me" },
    { id: "Seeing a session", title: "Seeing a session" },
    { id: "Google Search", title: "Google Search" },
    { id: "Saw a FEAT Sign", title: "Saw a FEAT Sign" },
    { id: "Social Media", title: "Social Media" },
    { id: "ClassPass", title: "ClassPass" },
    { id: "Other", title: "Other" },
  ];

  useEffect(() => {
    firstNameInputRef.current.focus();
    const { firstName, lastName, email, mobile } = preFillData;
    firstName && setValue("firstName", firstName);
    lastName && setValue("lastName", lastName);
    email && setValue("email", email);
    mobile && setValue("mobile", mobile);
  }, []);

  const onSubmit = (data) => {
    gaEvent("Form", "Submit", "Pre-training Questionnaire Submit");
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form_name,
        address: address?.label,
        ...data,
      }),
    })
      .then(afterSubmit)
      .catch((error) => alert(error));
  };
  const onError = (errors, e) => console.error(errors, e);

  const headingClassName =
    headingClassNames ||
    "text-2xl text-heading uppercase text-center leading-6 font-medium text-gray-900";

  const subHeadingClassName =
    "text-heading uppercase mb-4 text-lg text-feat-darkgray text-center";

  const constantLabelClassName = "text-base ";
  const labelClassName = constantLabelClassName + "text-feat-darkgrey-600";
  const errorLabelClassName = constantLabelClassName + "text-red-600";

  const constantInputClassName = "shadow-sm block w-full sm:text-md ";

  const inputClassName =
    constantInputClassName +
    "focus:ring-feat-blue-500 focus:border-feat-blue-500 border-gray-300 placeholder-feat-darkgrey-300";
  const errorInputClassName =
    constantInputClassName +
    "focus:ring-red-500 focus:border-red-500 border-red-300 placeholder-red-300";

  const constantRadioInputClassName = "h-4 w-4 text-feat-blue-600 ";
  const radioInputClassName =
    constantRadioInputClassName + "focus:ring-feat-blue-500 border-gray-300";

  const errorRadioInputClassName =
    constantRadioInputClassName + "focus:ring-red-500 border-red-300";

  const stepForm = () => {
    trigger().then((triggerIsValid) => {
      if (triggerIsValid) {
        setFormStep(formStep + 1);
      } else {
        if (!isValid) {
          console.log(errors);
        }
      }
    });
  };

  const captureSubmit = () => {
    if (formStep === 3) {
      handleSubmit(onSubmit, onError)();
    } else {
      stepForm();
    }
  };

  return (
    <>
      <div>
        <h2 className={headingClassName}>Pre Training Questionnaire</h2>
        <nav aria-label="Progress">
          <ol className="flex items-center justify-center mt-4">
            {steps.map((step, stepIdx) => (
              <li
                key={step.name}
                className={classNames(
                  stepIdx !== steps.length - 1 ? "pr-8 sm:pr-20" : "",
                  "relative"
                )}
              >
                {stepIdx < formStep ? (
                  <>
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="h-0.5 w-full bg-feat-blue-600" />
                    </div>
                    <div className="relative w-8 h-8 flex items-center justify-center bg-feat-blue-600 rounded-full">
                      <CheckIcon
                        className="w-5 h-5 text-white"
                        aria-hidden="true"
                      />
                      <span className="sr-only">{step.name}</span>
                    </div>
                  </>
                ) : stepIdx === formStep ? (
                  <>
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="h-0.5 w-full bg-gray-200" />
                    </div>
                    <div
                      className="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-feat-blue-600 rounded-full"
                      aria-current="step"
                    >
                      <span
                        className="h-2.5 w-2.5 bg-feat-blue-600 rounded-full"
                        aria-hidden="true"
                      />
                      <span className="sr-only">{step.name}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="h-0.5 w-full bg-gray-200" />
                    </div>
                    <div className="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full">
                      <span
                        className="h-2.5 w-2.5 bg-transparent rounded-full"
                        aria-hidden="true"
                      />
                      <span className="sr-only">{step.name}</span>
                    </div>
                  </>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          captureSubmit();
        }}
        name={form_name}
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value={form_name} />
        <input {...register("bot-field")} type="hidden" />
        {/** STEP 1 -> About You and Locations */}
        <div className={classNames("step-1", { hidden: formStep !== 1 })}>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <input
                type="text"
                {...firstNameRest}
                autoComplete="given-name"
                placeholder="First Name *"
                className={
                  errors["firstName"] ? errorInputClassName : inputClassName
                }
                ref={(e) => {
                  firstNameRef(e);
                  firstNameInputRef.current = e;
                }}
              />
            </div>

            <div className="sm:col-span-3">
              <input
                type="text"
                {...register("lastName", { required: formStep >= 1 })}
                autoComplete="family-name"
                placeholder="Last Name *"
                className={
                  errors["lastName"] ? errorInputClassName : inputClassName
                }
              />
            </div>

            <div className="sm:col-span-3">
              <input
                {...register("email", {
                  required: formStep >= 1,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format",
                  },
                })}
                type="email"
                autoComplete="email"
                placeholder="Email *"
                className={classNames(
                  "focus:ring-feat-blue-500 focus:border-feat-blue-500",
                  errors["email"] ? errorInputClassName : inputClassName
                )}
              />
            </div>
            <div className="sm:col-span-3">
              <PhoneInput
                name="mobile"
                control={control}
                rules={{ required: formStep >= 1 }}
                className={
                  errors["mobile"] ? errorInputClassName : inputClassName
                }
                defaultCountry="AU"
                type="tel"
                autoComplete="tel"
                placeholder="Mobile *"
              />
            </div>
            <div className="sm:col-span-6">
              <input
                type="hidden"
                name="address"
                value={locationSelected || ""}
              />
              <GooglePlacesAutocomplete
                selectProps={{
                  styles: {
                    control: (base, state) => ({
                      ...base,
                      "&:hover": { borderColor: "#4dc8e9" },
                      "&:focus": { borderColor: "#4dc8e9" },
                      borderRadius: 0,
                    }),
                  },
                  components: {
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  },
                  placeholder: "Address",
                  className: inputClassName,
                  value: address,
                  onChange: setAddress,
                }}
                apiKey={googleApiKey}
                autocompletionRequest={{
                  location: { lat: -33.8753, lng: 151.2321 },
                  radius: 20000,
                  componentRestrictions: {
                    country: ["au"],
                  },
                }}
              />
            </div>
            <div className="sm:col-span-2">
              <input type="hidden" name="dob" value={locationSelected || ""} />
              <Controller
                name="dob"
                control={control}
                autoComplete="bday"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DatePicker
                    selected={value}
                    dateFormat="dd/MM/yyyy"
                    onChange={onChange}
                    onBlur={() => {
                      onBlur();
                    }}
                    className={inputClassName}
                    placeholderText="Date of Birth"
                    inputRef={ref}
                  />
                )}
              />
            </div>
            <div className="sm:col-span-2">
              <input
                type="text"
                {...register("gender")}
                autoComplete="sex"
                placeholder="Gender"
                className={inputClassName}
              />
            </div>
            <div className="sm:col-span-2">
              <input
                type="text"
                {...register("shirtSize")}
                placeholder="Shirt Size (for a FEAT Shirt)"
                className={inputClassName}
              />
            </div>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            {/* Location Choice */}
            <div className="sm:col-span-6">
              <input
                type="hidden"
                name="locationsChoice"
                value={locationSelected || ""}
              />
              <Controller
                name="locationsChoice"
                control={control}
                defaultValue={null}
                rules={{ required: formStep >= 1 }}
                render={({ field: { onChange } }) => (
                  <Listbox
                    value={locationSelected || ""}
                    onChange={(e) => {
                      onChange(e.frontmatter.location);
                      setLocationSelected(e);
                    }}
                  >
                    <div className="relative">
                      <Listbox.Button
                        className={classNames(
                          "relative w-full bg-white border shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1  sm:text-md",
                          errors["locationsChoice"]
                            ? "border-red-300 focus:ring-red focus:border-red"
                            : "border-gray-300 focus:ring-feat-blue focus:border-feat-blue"
                        )}
                      >
                        <span className="w-full inline-flex truncate">
                          {locationSelected ? (
                            <>
                              <span className="truncate">
                                {locationSelected?.frontmatter.location}
                              </span>
                              <span className="ml-2 truncate text-feat-darkgrey">
                                {locationSelected?.frontmatter.sub_category}
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                className={classNames(
                                  "truncate",
                                  errors["locationsChoice"]
                                    ? "text-red-300"
                                    : " text-feat-darkgrey-300"
                                )}
                              >
                                Select a Training Location *
                              </span>
                            </>
                          )}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon
                            className={classNames(
                              "h-5 w-5",
                              errors["locationsChoice"]
                                ? "text-red-400"
                                : " text-feat-darkgrey-400"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                          {locations.map(({ node: location }) => (
                            <Listbox.Option
                              key={location.frontmatter.location}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "text-white bg-feat-blue"
                                    : "text-gray-900",
                                  "cursor-default select-none relative py-2 pl-3 pr-9"
                                )
                              }
                              value={location}
                            >
                              {({ locationSelected, active }) => (
                                <>
                                  <div className="flex">
                                    <span
                                      className={classNames(
                                        locationSelected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "truncate"
                                      )}
                                    >
                                      {location.frontmatter.location}
                                    </span>
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-feat-blue-50"
                                          : "text-feat-darkgrey",
                                        "ml-2 truncate"
                                      )}
                                    >
                                      {location.frontmatter.sub_category}
                                    </span>
                                  </div>

                                  {locationSelected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-feat-blue",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                )}
              />
            </div>
            {/* End Location Choice */}

            {/* What are you looking to get out of training? */}
            <div className="sm:col-span-6">
              <textarea
                type="text"
                rows="2"
                {...register("wantToGetOutOf", { required: formStep >= 1 })}
                placeholder="What are you looking to get out of training? *"
                className={
                  errors["wantToGetOutOf"]
                    ? errorInputClassName
                    : inputClassName
                }
              ></textarea>
            </div>

            {/* Are you currently Training */}
            <div className="sm:col-span-6">
              <div
                className={classNames(
                  { "text-gray-700": !errors["currentlyTraining"] },
                  { "text-red-600": errors["currentlyTraining"] }
                )}
              >
                Are you currently training? *
              </div>
              <div className="mt-4 space-x-8 flex flex-row">
                <div className="flex items-center">
                  <input
                    {...register("currentlyTraining", {
                      required: formStep >= 1,
                      onChange: (e) => setCurrentlyTraining(e.target.value),
                    })}
                    id="currentlyTraining-Yes"
                    type="radio"
                    value="Yes"
                    className={
                      errors["currentlyTraining"]
                        ? errorRadioInputClassName
                        : radioInputClassName
                    }
                    checked={currentlyTraining === "Yes"}
                  />
                  <label
                    htmlFor="currentlyTraining-Yes"
                    className={classNames(
                      "ml-3 block text-sm font-medium",
                      { "text-gray-700": !errors["currentlyTraining"] },
                      { "text-red-600": errors["currentlyTraining"] }
                    )}
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    {...register("currentlyTraining", {
                      required: formStep >= 1,
                      onChange: (e) => setCurrentlyTraining(e.target.value),
                    })}
                    id="currentlyTraining-No"
                    type="radio"
                    value="No"
                    className={
                      errors["currentlyTraining"]
                        ? errorRadioInputClassName
                        : radioInputClassName
                    }
                    checked={currentlyTraining === "No"}
                  />
                  <label
                    htmlFor="currentlyTraining-No"
                    className={classNames(
                      "ml-3 block text-sm font-medium",
                      { "text-gray-700": !errors["currentlyTraining"] },
                      { "text-red-600": errors["currentlyTraining"] }
                    )}
                  >
                    No
                  </label>
                </div>
              </div>
            </div>

            {/* Have you done any training */}
            <div className="sm:col-span-6">
              <input
                type="text"
                {...register("previousTraining", {
                  required: formStep >= 1 && currentlyTraining === "No",
                })}
                placeholder="Have you done any training in the past? *"
                className={classNames(
                  errors["previousTraining"]
                    ? errorInputClassName
                    : inputClassName,
                  watchCurrentlyTraining === "Yes" && "hidden"
                )}
              />

              <input
                type="text"
                {...register("typeOfTrainingAndFrequency", {
                  required: formStep >= 1 && currentlyTraining === "Yes",
                })}
                placeholder="What type of training and how often? *"
                className={classNames(
                  errors["typeOfTrainingAndFrequency"]
                    ? errorInputClassName
                    : inputClassName,
                  watchCurrentlyTraining !== "Yes" && "hidden"
                )}
              />
            </div>

            <div className="sm:col-span-6">
              <input
                type="hidden"
                name="hearAboutUs"
                value={hearAboutUsSelected || ""}
              />
              <Controller
                name="hearAboutUs"
                control={control}
                defaultValue={null}
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <Listbox
                    value={hearAboutUsSelected || ""}
                    onChange={(e) => {
                      onChange(e.title);
                      setHearAboutUsSelected(e);
                    }}
                  >
                    <div className="relative">
                      <Listbox.Button
                        className={classNames(
                          "relative w-full bg-white border shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1  sm:text-md",
                          errors["hearAboutUs"]
                            ? "border-red-300 focus:ring-red focus:border-red"
                            : "border-gray-300 focus:ring-feat-blue focus:border-feat-blue"
                        )}
                      >
                        <span className="w-full inline-flex truncate">
                          {hearAboutUsSelected ? (
                            <>
                              <span className="truncate">
                                {hearAboutUsSelected?.title}
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                className={classNames(
                                  "truncate",
                                  errors["hearAboutUs"]
                                    ? "text-red-300"
                                    : " text-feat-darkgrey-300"
                                )}
                              >
                                How did you hear about us?
                              </span>
                            </>
                          )}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                          {hearAboutUsOptions.map((hearAboutUsOption) => (
                            <Listbox.Option
                              key={hearAboutUsOption.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "text-white bg-feat-blue"
                                    : "text-gray-900",
                                  "cursor-default select-none relative py-2 pl-3 pr-9"
                                )
                              }
                              value={hearAboutUsOption}
                            >
                              {({ hearAboutUsSelected, active }) => (
                                <>
                                  <div className="flex">
                                    <span
                                      className={classNames(
                                        hearAboutUsSelected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "truncate"
                                      )}
                                    >
                                      {hearAboutUsOption.title}
                                    </span>
                                  </div>

                                  {hearAboutUsSelected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-feat-blue",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                )}
              />
            </div>
          </div>
        </div>
        {/** END STEP 1 */}
        {/** STEP 2 -> Health Information */}
        <div className={classNames("step-2", { hidden: formStep !== 2 })}>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <div className={subHeadingClassName}>Health Information</div>
              <div className={labelClassName}>
                Do you have or have a history of any of the following?
              </div>
              <input type="hidden" name="medicalItems" />
              <div className="grid  gap-y-2 gap-x-4 sm:grid-cols-3 grid-cols-2">
                {healthOptions.map((health_option) => (
                  <div
                    className="relative flex items-start cursor-pointer select-none"
                    key={health_option}
                  >
                    <div className="flex items-center h-5">
                      <input
                        id={"health_" + _.camelCase(health_option)}
                        type="checkbox"
                        {...register("health_" + _.camelCase(health_option))}
                        className={radioInputClassName}
                      />
                    </div>
                    <div className="ml-3 text-base cursor-pointer">
                      <label
                        htmlFor={"health_" + _.camelCase(health_option)}
                        className="font-medium text-gray-700 cursor-pointer"
                      >
                        {health_option}
                      </label>
                    </div>
                  </div>
                ))}
              </div>

              {watchHealthOther === true ? (
                <input
                  type="text"
                  {...register("medicalItemsOtherField", {
                    required: formStep >= 2,
                  })}
                  placeholder="Tell us more *"
                  className={classNames(
                    "mt-4",
                    errors["medicalItemsOtherField"]
                      ? errorInputClassName
                      : inputClassName
                  )}
                />
              ) : (
                <input type="hidden" name="medicalItemsOtherField" />
              )}
            </div>
            <div className="sm:col-span-6">
              {healthInformationYesNoMore.map((question) => (
                <div key={question.name}>
                  {/* Are you currently Training */}
                  <div className="sm:col-span-6 mt-4">
                    <div
                      className={
                        errors[question.name]
                          ? errorLabelClassName
                          : labelClassName
                      }
                    >
                      {`${question.label} *`}
                    </div>
                    <div className="mt-2 space-x-8 flex flex-row">
                      <div className="flex items-center">
                        <input
                          {...register(question.name, {
                            required: formStep >= 2,
                          })}
                          id={`${question.name}-Yes`}
                          type="radio"
                          value="Yes"
                          className={
                            errors[question.name]
                              ? errorRadioInputClassName
                              : radioInputClassName
                          }
                        />
                        <label
                          htmlFor={`${question.name}-Yes`}
                          className={classNames(
                            "ml-3 block text-sm font-medium",
                            { "text-gray-700": !errors[question.name] },
                            { "text-red-600": errors[question.name] }
                          )}
                        >
                          Yes
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          {...register(question.name, {
                            required: formStep >= 2,
                          })}
                          id={`${question.name}-No`}
                          type="radio"
                          value="No"
                          className={
                            errors[question.name]
                              ? errorRadioInputClassName
                              : radioInputClassName
                          }
                        />
                        <label
                          htmlFor={`${question.name}-No`}
                          className={classNames(
                            "ml-3 block text-sm font-medium",
                            { "text-gray-700": !errors[question.name] },
                            { "text-red-600": errors[question.name] }
                          )}
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  {question.askMore === true && (
                    <div className="sm:col-span-6">
                      {question.watch === "Yes" ? (
                        <input
                          type="text"
                          {...register(`${question.name}More`, {
                            required: question.watch === "Yes",
                          })}
                          placeholder="Tell us about these *"
                          className={classNames(
                            "mt-2",
                            errors[`${question.name}More`]
                              ? errorInputClassName
                              : inputClassName
                          )}
                        />
                      ) : (
                        <input
                          type="hidden"
                          {...register(`${question.name}More`)}
                        />
                      )}
                    </div>
                  )}
                </div>
              ))}
              <>
                {/* Are you currently Training */}
                <div className="sm:col-span-6 mt-4">
                  <div
                    className={
                      errors["vaccinated"]
                        ? errorLabelClassName
                        : labelClassName
                    }
                  >
                    Have you had at least two doses of a COVID-19 vaccine? *
                  </div>
                  <div className="mt-2 space-x-8 flex flex-row">
                    <div className="flex items-center">
                      <input
                        {...register("vaccinated", { required: formStep >= 2 })}
                        id={`vaccinated-Yes`}
                        type="radio"
                        value="Yes"
                        className={
                          errors["vaccinated"]
                            ? errorRadioInputClassName
                            : radioInputClassName
                        }
                      />
                      <label
                        htmlFor={`vaccinated-Yes`}
                        className={classNames(
                          "ml-3 block text-sm font-medium",
                          { "text-gray-700": !errors["vaccinated"] },
                          { "text-red-600": errors["vaccinated"] }
                        )}
                      >
                        Yes
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        {...register("vaccinated", { required: formStep >= 2 })}
                        id={`vaccinated-No`}
                        type="radio"
                        value="No"
                        className={
                          errors["vaccinated"]
                            ? errorRadioInputClassName
                            : radioInputClassName
                        }
                      />
                      <label
                        htmlFor={`vaccinated-No`}
                        className={classNames(
                          "ml-3 block text-sm font-medium",
                          { "text-gray-700": !errors["vaccinated"] },
                          { "text-red-600": errors["vaccinated"] }
                        )}
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <div
                    className={classNames("sm:col-span-6 mt-4", {
                      hidden: watchVaccinated !== "No",
                    })}
                  >
                    <div
                      className={
                        errors["vaccineMedicalException"]
                          ? errorLabelClassName
                          : labelClassName
                      }
                    >
                      Do you have a medical exemption preventing you from having
                      the COVID-19 vaccine? You will need to show this. *
                    </div>
                    <div className="mt-2 space-x-8 flex flex-row">
                      <div className="flex items-center">
                        <input
                          {...register("vaccineMedicalException", {
                            required: formStep >= 2,
                          })}
                          id={`vaccineMedicalException-Yes`}
                          type="radio"
                          value="Yes"
                          className={
                            errors["vaccineMedicalException"]
                              ? errorRadioInputClassName
                              : radioInputClassName
                          }
                        />
                        <label
                          htmlFor={`vaccineMedicalException-Yes`}
                          className={classNames(
                            "ml-3 block text-sm font-medium",
                            {
                              "text-gray-700":
                                !errors["vaccineMedicalException"],
                            },
                            {
                              "text-red-600": errors["vaccineMedicalException"],
                            }
                          )}
                        >
                          Yes
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          {...register("vaccineMedicalException", {
                            required: formStep >= 2 && watchVaccinated === "No",
                          })}
                          id={`$vaccineMedicalException-No`}
                          type="radio"
                          value="No"
                          className={
                            errors["vaccineMedicalException"]
                              ? errorRadioInputClassName
                              : radioInputClassName
                          }
                        />
                        <label
                          htmlFor={`vaccineMedicalException-No`}
                          className={classNames(
                            "ml-3 block text-sm font-medium",
                            {
                              "text-gray-700":
                                !errors["vaccineMedicalException"],
                            },
                            {
                              "text-red-600": errors["vaccineMedicalException"],
                            }
                          )}
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
        {/** END STEP 2 - Health Information */}
        {/** STEP 3 -> Emergency Contact & Waiver */}
        <div className={classNames("step-3", { hidden: formStep !== 3 })}>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            {/* <div className="sm:col-span-6">Emergency Contact</div> */}
            <div className={classNames("sm:col-span-6", subHeadingClassName)}>
              Emergency Contact Details
            </div>
            <div className="sm:col-span-3">
              <input
                type="text"
                {...register("emergencyContactName", {
                  required: formStep >= 3,
                })}
                placeholder="Emergency Contact Name *"
                className={
                  errors["emergencyContactName"]
                    ? errorInputClassName
                    : inputClassName
                }
              />
            </div>
            <div className="sm:col-span-3">
              <PhoneInput
                name="emergencyContactNumber"
                control={control}
                rules={{ required: formStep >= 3 }}
                className={
                  errors["emergencyContactNumber"]
                    ? errorInputClassName
                    : inputClassName
                }
                defaultCountry="AU"
                type="tel"
                autoComplete="tel"
                placeholder="Emergency Contact Number *"
              />
            </div>
            <div className="sm:col-span-3">
              <input
                type="text"
                {...register("emergencyContactRelation", {
                  required: formStep >= 3,
                })}
                placeholder="How is your Emergency Contact related to you? *"
                className={
                  errors["emergencyContactRelation"]
                    ? errorInputClassName
                    : inputClassName
                }
              />
            </div>
            <div className="sm:col-span-3">
              <input
                type="text"
                {...register("emergencyContactOther")}
                placeholder="Emergency Details - Anything else we should know?"
                className={inputClassName}
              />
            </div>
          </div>
          {/* End Emergency Contact Section */}
          <hr className="border-feat-lightgrey mt-6" />
          {/** DECLARATION & WAIVER */}
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className={classNames("sm:col-span-6", subHeadingClassName)}>
              Declaration & Waiver
            </div>

            <div className="sm:col-span-6 text-sm text-feat-darkgray-600 -mt-4 max-w-2xl mx-auto space-y-2">
              <p>
                I understand that I may participate in physical activities which
                may expose me to certain risks and that I do so at my own risk.
                I will not hold Fitness FEAT, or any of its servants and agents,
                liable for any injury, loss, damage or death caused to me or my
                property whether by negligence, omission and breach of contract
                or in any way whatsoever.
              </p>
              <p>
                I undertake to complete a new pre activity questionnaire in the
                event of any changes in my medical status during the time
                participating in training. I understand that it is my
                responsibility to advise Fitness FEAT of any medical/physical
                conditions that may prevent me from exercising, and that I
                participate in exercise at my own risk.
              </p>
              <div className="relative flex items-start sm:col-span-6 mt-2 items-center">
                <div className="flex items-center h-5">
                  <input
                    id="agreeToTerms"
                    type="checkbox"
                    {...register("agreeToTerms", { required: formStep >= 3 })}
                    className={
                      errors["agreeToTerms"]
                        ? errorRadioInputClassName
                        : radioInputClassName
                    }
                  />
                </div>
                <div className="ml-3 text-base">
                  <label
                    htmlFor="agreeToTerms"
                    className={classNames(
                      "font-medium",
                      errors["agreeToTerms"] ? "text-red-600" : "text-gray-700"
                    )}
                  >
                    I agree to the above statement
                  </label>
                </div>
              </div>
            </div>
          </div>
          {/** END DECLARATION & WAIVER */}
        </div>
        {/** END STEP 3 */}
        <div className="pt-5">
          <div className="flex justify-between flex-row-reverse">
            <div
              className={classNames("complete-button", {
                hidden: formStep !== 3,
              })}
            >
              <Button
                type="submit"
                className="uppercase font-heading text-center text-white bg-feat-blue hover:bg-feat-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-feat-blue"
              >
                Submit
              </Button>
            </div>
            <div
              className={classNames("next-button", { hidden: formStep === 3 })}
            >
              <Button
                onClick={() => stepForm()}
                className="uppercase font-heading text-center text-white bg-feat-blue hover:bg-feat-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-feat-blue"
              >
                Next
              </Button>
            </div>
            <div
              className={classNames("prev-button", { hidden: formStep < 2 })}
            >
              <Button
                onClick={() => setFormStep(formStep - 1)}
                className="uppercase font-heading text-center text-feat-darkgrey-300 border-feat-darkgrey-300 hover:bg-feat-lightgrey-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-feat-blue"
              >
                Back
              </Button>
            </div>
          </div>

          <div
            className={classNames(
              "pt-2 text-xs text-feat-lightgray-700 text-right",
              {
                hidden: formStep !== 3,
              }
            )}
          >
            By submitting this form, you agree to our FEAT Fitness{" "}
            <a
              href="/privacy-policy"
              target="_blank"
              className="text-feat-blue-300 cursor-pointer"
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </form>
    </>
  );
}
