import React from "react";
import Helmet from "react-helmet";
import config from "../../../meta/config";

const Seo = ({ title, meta_title, meta_desc, cover, slug, faq, noindex }) => {
  let postURL = config.siteUrl + slug;
  const realPrefix = config.pathPrefix === "/" ? "" : config.pathPrefix;
  let image = config.siteUrl + realPrefix + cover;
  const blogURL = config.siteUrl + config.pathPrefix;
  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: blogURL,
      name: title,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : "",
    },
  ];

  if (faq) {
    schemaOrgJSONLD.push({
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: faq.question,
          acceptedAnswer: {
            "@type": "Answer",
            text: faq.answer,
          },
        },
      ],
    });
  }

  return (
    <Helmet>
      <title>{meta_title}</title>
      {/* General tags */}
      <meta name="description" content={meta_desc} />
      <meta name="image" content={cover} />
      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
      {/* OpenGraph tags */}
      <meta property="og:url" content={postURL} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={meta_desc} />
      <meta property="og:image" content={image} />
      <meta
        property="fb:app_id"
        content={config.siteFBAppID ? config.siteFBAppID : ""}
      />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:creator"
        content={config.userTwitter ? config.userTwitter : ""}
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={meta_desc} />
      <meta name="twitter:image" content={image} />
      {noindex && noindex === true && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

export default Seo;
