import React from "react";

export default function Button({ className, children, type, ...props }) {
  const buttonType = type || "button";
  return (
    <button
      type={buttonType}
      className={`inline-flex justify-center items-center px-6 py-3 border border-transparent text-base font-medium shadow-sm ${className}`}
      {...props}
    >
      {children}
    </button>
  );
}
